@import '../../../../styles/variables.scss';

.#{$prefix}__picture {
    width: 100%;
    min-width: 320px;
    margin-bottom: 20px;
}

.#{$prefix}__controls {
    margin-bottom: 30px;
    width: 100%;
}

.#{$prefix}__button-next {
    margin-bottom: 5px;
    width: 100%;
}

.#{$prefix}__button-select-all {
    width: 100%;
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix}__layout-center {
        order: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 50px 100px;
        z-index: 5;
        width: calc(100% - 540px);
    }
    .#{$prefix}__layout-right {
        order: 1;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 45px;
        border-right: 1px solid var(--border-gray);
        width: 540px;
    }
    .#{$prefix}__button-next {
        margin-bottom: 0;
    }
    .#{$prefix}__button-select-all {
        margin-bottom: 10px;
    }
    .#{$prefix}__picture {
        margin: 0;
    }
}
