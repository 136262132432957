@import '../../../../styles/variables.scss';

.#{$prefix}__subtitle {
    margin-bottom: 50px;
}

.#{$prefix}__category {
    position: relative;
    padding-left: 45px;
    &::before {
        content: '';
        position: absolute;
        top: -15px;
        left: 0;
        width: 30px;
        height: 48px;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
    }
    &--0 {
        &::before {
            background-image: url('../../../../assets/svg/clothes/tops.svg');
        }
    }
}

.#{$prefix}__products {
    display: flex;
    overflow: hidden;
}

.#{$prefix}__product {
    width: 100%;
    flex-shrink: 0;
}

.#{$prefix}__picture {
    width: 325px;
    height: 530px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 10px;
}

.#{$prefix}__details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.#{$prefix}__brand {
    color: var(--text-gray);
    width: 260px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.#{$prefix}__question {
    text-align: center;
    margin-bottom: 25px;
}

.#{$prefix}__controls {
    display: flex;
    grid-gap: 5px;
}

.#{$prefix}__controls-btn {
    width: 50%;
}

.#{$prefix}__button-close {
    background-color: #fff;
    margin-bottom: 15px;
}

.#{$prefix}__overlay-dark {
    position: fixed;
    background-color: var(--text-black);
    opacity: 0.4;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 150;
}

.#{$prefix}__dislikes {
    position: fixed;
    text-align: right;
    left: 0;
    bottom: 0;
    width: 210px;
    z-index: 170;
}

.#{$prefix}__dislikes-content {
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 15px 25px;
    text-align: center;
}

.#{$prefix}__dislikes-title {
    margin-bottom: 20px;
}

.#{$prefix}__dislikes-controls {
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix}__layout-center {
        order: 2;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 50px;
        width: 100%;
        z-index: 5;
    }
    .#{$prefix}__layout-right {
        order: 1;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 45px;
        border-right: 1px solid var(--border-gray);
        max-width: 404px;
    }
    .#{$prefix}__products {
        flex-wrap: wrap;
        grid-gap: 20px;
    }
    .#{$prefix}__product {
        width: auto;
        position: relative;
    }
    .#{$prefix}__picture {
        width: 205px;
        height: 260px;
    }
    .#{$prefix}__details {
        display: none;
    }
    .#{$prefix}__dislikes-desktop {
        width: 100%;
        border: 1px solid var(--red);
        padding: 10px;
        background-color: #fff;
        position: absolute;
        bottom: 60px;
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
    }
    .#{$prefix}__button-close {
        position: absolute;
        right: 0;
        top: 5px;
        border: none;
    }
    .#{$prefix}__dislikes-title {
        margin-bottom: 5px;
    }
}
