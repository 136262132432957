@import '../../../../styles/variables.scss';

.#{$prefix}__prices {
    width: 100%;
    display: flex;
    grid-gap: 5px;
}

.#{$prefix}__options {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 15px;
    flex-direction: column;
}

.#{$prefix}__options-prices-sex {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    align-items: flex-start;
    margin-bottom: 10px;
}

.#{$prefix}__looks {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    margin-bottom: 30px;
}

.#{$prefix}__look-picture {
    width: 155px;
    height: 220px;
    background-size: cover;
    background-repeat: no-repeat;
}

.#{$prefix}__product-card {
    width: 155px;
}
.#{$prefix}__product-card-pic {
    width: 155px;
    height: 220px;
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix}__page-title-left {
        width: auto;
        padding: 0;
    }
    .#{$prefix}__options {
        flex-direction: row;
        margin-bottom: 35px;
    }
    .#{$prefix}__prices {
        width: auto;
    }
    .#{$prefix}__looks {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 20px;
    }
    .#{$prefix}__look-picture {
        width: 250px;
        height: 350px;
    }
    .#{$prefix}__controls {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .#{$prefix}__btn {
        width: 520px;
    }
    .#{$prefix}__options-prices-sex {
        flex-direction: row;
        grid-gap: 50px;
        align-items: center;
        margin: 0;
    }
    .#{$prefix}__product-card {
        width: 250px;
    }
    .#{$prefix}__product-card-pic {
        width: 250px;
        height: 350px;
    }
}
