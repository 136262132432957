@import '../../../styles/variables.scss';

$desktop-side-gap: 50px;

.#{$prefix} {
    position: relative;
    min-height: calc(100vh - #{$mobile-header-height});
    padding: 50px 0 30px 0;
}

.#{$prefix}__header {
    width: 250px;
    margin: 0 auto;
    text-align: center;
}

.#{$prefix}__title {
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 25px;
    background: radial-gradient(77.73% 77.73% at 98.52% 96.25%, #f98bda 0%, rgba(128, 76, 239, 0.29) 50.52%, rgba(91, 216, 216, 0) 100%),
        radial-gradient(141.73% 105.23% at 50% -7.16%, #e1f8ff 0%, rgba(160, 198, 255, 0) 50.73%, rgba(162, 147, 255, 0) 100%),
        radial-gradient(112.27% 48.54% at 1.59% 50%, rgba(255, 130, 227, 0.5) 0%, rgba(255, 123, 234, 0.095) 53.91%, rgba(254, 216, 255, 0) 100%),
        linear-gradient(153.07deg, #8177f3 6.37%, #8fc8f1 83.82%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-radius: 20px;
}

.#{$prefix}__subtitle {
    margin: 0 auto 80px auto;
}

.#{$prefix}__nav {
    margin-bottom: 40px;
}

.#{$prefix}__menu-list {
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
}

.#{$prefix}__menu-item {
    width: 325px;
    height: 235px;
    margin: 0 auto;
    border-radius: 20px;
    background: radial-gradient(77.73% 77.73% at 98.52% 96.25%, #f98bda 0%, rgba(128, 76, 239, 0.29) 50.52%, rgba(91, 216, 216, 0) 100%),
        radial-gradient(141.73% 105.23% at 50% -7.16%, rgba(225, 248, 255, 0.7) 0%, rgba(160, 198, 255, 0) 50.73%, rgba(162, 147, 255, 0) 100%),
        radial-gradient(112.27% 48.54% at 1.59% 50%, rgba(255, 130, 227, 0.5) 0%, rgba(255, 123, 234, 0.095) 53.91%, rgba(254, 216, 255, 0) 100%),
        linear-gradient(153.07deg, #8177f3 6.37%, #8fc8f1 83.82%);
    &:nth-child(2) {
        background: radial-gradient(119.35% 65.46% at 127.68% 15.55%, #fff0ba 0%, rgba(250, 144, 78, 0) 100%),
            radial-gradient(81.09% 160.32% at 10.91% 7.43%, rgba(255, 132, 79, 0.7) 0%, rgba(253, 169, 107, 0) 100%),
            radial-gradient(38.62% 73.86% at 46.15% 108.51%, #fff8bc 2.1%, rgba(254, 180, 120, 0) 100%),
            linear-gradient(135.54deg, #f2681c 1.29%, #ffb36c 75.24%);
    }
    &:nth-child(3) {
        background: radial-gradient(43.81% 69.57% at 105.23% 0%, #eefecb 0%, rgba(221, 245, 254, 0) 100%),
            radial-gradient(39.07% 57.09% at 26% -11.91%, rgba(82, 245, 255, 0.7) 0%, rgba(82, 245, 255, 0) 100%),
            radial-gradient(53.23% 67.02% at 49.85% 117.23%, #b4ffd9 0%, rgba(121, 188, 239, 0.7) 100%),
            linear-gradient(209.72deg, #5baceb 17.59%, #e8f1ef 90.28%);
    }
    &:nth-child(4) {
        background: radial-gradient(84.92% 121.2% at 1.65% 36.32%, rgba(253, 247, 105, 0.5) 0%, rgba(107, 189, 82, 0) 53.91%),
            radial-gradient(77.38% 101.24% at 1.38% 112.34%, #42bb6b 0%, rgba(152, 210, 72, 0) 100%),
            radial-gradient(59.98% 77.69% at 101.43% 50%, #dfe634 0%, rgba(120, 189, 80, 0) 100%),
            linear-gradient(204.31deg, #95d448 14.17%, rgba(121, 186, 93, 0) 85.05%), #6ab54e;
    }
    &:nth-child(5) {
        display: none;
    }
}

.#{$prefix}__menu-item-link {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 22px 30px 55px 30px;
    color: #fff;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
}

.#{$prefix}__trends {
    display: none;
}

.#{$prefix}__trends-title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    position: relative;
    position: relative;
    padding-left: 40px;
    margin-bottom: 35px;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 24px;
        height: 24px;
        background: url(../../../../src/assets/svg/arrows-double-down.svg) no-repeat;
    }
}

.#{$prefix}__line {
    display: none;
    margin: 0 -1 * $desktop-side-gap 40px -1 * $desktop-side-gap;
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix} {
        padding: 90px $desktop-side-gap 70px $desktop-side-gap;
    }
    .#{$prefix}__header {
        width: auto;
        margin: 0;
        text-align: left;
    }
    .#{$prefix}__title {
        font-weight: 500;
        font-size: 64px;
        line-height: 72px;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin-bottom: 45px;
        text-align: left;
        -webkit-text-fill-color: var(--text-black);
    }

    .#{$prefix}__subtitle {
        margin-bottom: 65px;
    }

    .#{$prefix}__menu-list {
        flex-direction: row;
        grid-gap: 20px;
    }

    .#{$prefix}__menu-item {
        &:nth-child(4) {
            display: none;
        }
        flex-direction: row;
        margin: 0;
        width: calc((100% - 2 * 20px) / 3);
        position: relative;
        &::before {
            content: '';
            position: absolute;
            left: 30px;
            bottom: 30px;
            width: 30px;
            height: 30px;
            background: rgba(255, 255, 255, 0.3) url('../../../assets/svg/arrow-tick-right-white.svg') no-repeat center;
            border-radius: 50%;
        }
    }

    .#{$prefix}__menu-item-link {
        align-items: flex-start;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
    }

    .#{$prefix}__facade-svg-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 70px;
        svg {
            width: 100%;
        }
    }

    .#{$prefix}__line,
    .#{$prefix}__trends {
        display: block;
    }
}
