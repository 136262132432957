@font-face {
    font-family: 'Aktiv Grotesk Corp';
    src: url('../assets/fonts/AktivGroteskCorp-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Aktiv Grotesk Corp';
    src: url('../assets/fonts/AktivGroteskCorp-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Aktiv Grotesk Corp';
    src: url('../assets/fonts/AktivGroteskCorp-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Aktiv Grotesk Corp';
    src: url('../assets/fonts/AktivGroteskCorp-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Aktiv Grotesk Corp';
    src: url('../assets/fonts/AktivGroteskCorp-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Aktiv Grotesk Corp';
    src: url('../assets/fonts/AktivGroteskCorp-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Aktiv Grotesk Corp';
    src: url('../assets/fonts/AktivGroteskCorp-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Aktiv Grotesk Corp';
    src: url('../assets/fonts/AktivGroteskCorp-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
