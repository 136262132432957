@import '../../../styles/variables.scss';

$border-radius: 40px;

.#{$prefix}__sex-toggle {
    background-color: #fff;
    width: 100%;
    border: 1px solid var(--border-gray);
    display: flex;
    border-radius: $border-radius;

    &--small {
        height: $option-btn-small-height;
    }

    &--big {
        height: $option-btn-big-height;
    }
}

.#{$prefix}__sex-toggle-label {
    width: 50%;
}

.#{$prefix}__sex-toggle-btn {
    padding: 0 30px;
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    &::before {
        display: inline-block;
        content: url('../../../assets/svg/triangle-top-black.svg');
        margin-right: 3px;
    }
}

.#{$prefix}__sex-toggle-label:nth-child(2) {
    .#{$prefix}__sex-toggle-btn {
        &::before {
            transform: rotate(180deg);
        }
    }
}

.#{$prefix}__sex-toggle-input {
    display: none;
    &:checked {
        & ~ .#{$prefix}__sex-toggle-btn {
            border-color: var(--text-black);
            border-radius: $border-radius;
        }
    }
}

@media screen and(min-width: $desktop-small) {
    .#{$prefix}__sex-toggle {
        // &--small {
        //     height: $option-btn-small-height;
        // }

        &--big {
            height: $option-btn-big-height-desktop;
        }
    }
}
