@import './variables.scss';

.#{$prefix}__button-reject,
.#{$prefix}__button-no,
.#{$prefix}__button-yes,
.#{$prefix}__button-main,
.#{$prefix}__button-main-black,
.#{$prefix}__button,
.#{$prefix}__button-small {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    &:disabled {
        cursor: not-allowed;
    }
}

.#{$prefix}__button-main,
.#{$prefix}__button-main-black,
.#{$prefix}__button {
    width: 325px;
    height: 65px;
}

.#{$prefix}__button-main {
    background-color: #fff;
    color: var(--text-black);
    border: none;
}

.#{$prefix}__button-main-black {
    background-color: var(--text-black);
    color: #fff;
    border: none;
    &:hover {
        &::after {
            content: '';
            width: 15px;
            height: 7px;
            background: url('../assets/svg/arrow-right.svg') no-repeat center;
            position: absolute;
            right: 20px;
        }
    }
    &:disabled {
        background-color: var(--border-gray);
        color: var(--text-gray);
        &:hover {
            &::after {
                content: none;
            }
        }
    }
}

.#{$prefix}__button {
    border: 1px solid var(--border-gray);
    background-color: unset;
    &:hover {
        border: 1px solid var(--text-black);
    }
    &:active {
        border: 1px solid var(--text-black);
        border-radius: 32px;
    }
}

.#{$prefix}__button-small {
    height: 30px;
    font-size: 13px;
    line-height: 15px;
    padding: 7px 14px;
    background-color: var(--text-black);
    color: #fff;
    border: none;
    border-radius: 14px;
    cursor: pointer;
}

.#{$prefix}__button-close {
    position: relative;
    width: $round-btn-size;
    height: $round-btn-size;
    border-radius: 50%;
    border: 1px solid var(--text-black);
    background: transparent url('../assets/svg/x.svg') no-repeat center;
    cursor: pointer;
}

.#{$prefix}__button-no,
.#{$prefix}__button-yes {
    color: var(--text-black);
    border: 1px solid var(--red);
    padding: 24px 40px;
    background: #fff url('../assets/svg/x.svg') no-repeat calc(50% + 25px) center;
    &:hover {
        color: #fff;
        &::after {
            content: '';
            width: 15px;
            height: 7px;

            position: absolute;
            right: 20px;
        }
    }
}

.#{$prefix}__button-no {
    &:hover,
    &--selected {
        background-color: var(--red);
        background-image: url('../assets/svg/x-white.svg');
    }
}

.#{$prefix}__button-yes {
    border: 1px solid var(--green);
    background: #fff url('../assets/svg/tick.svg') no-repeat calc(50% + 25px) center;
    &:hover,
    &--selected {
        background-color: var(--green);
        background-image: url('../assets/svg/tick-white.svg');
    }
}

.#{$prefix}__button-reject {
    border: 1px solid var(--red);
    background-color: #fff;
    padding: 24px 40px;
    &:hover {
        background-color: var(--red);
        color: #fff;
    }
}

.#{$prefix}__button-refresh,
.#{$prefix}__button-like {
    position: absolute;
    background-color: transparent;
    width: 36px;
    height: 36px;
    border: none;
    cursor: pointer;
    &:hover {
        transform: scale(1.1);
    }
}

.#{$prefix}__button-refresh {
    top: 0;
    left: 0;
    background: transparent url('../assets/svg/refresh.svg') no-repeat center;
    transition: transform ease-in-out 0.2s;
    &:active {
        transform: rotate(-180deg);
    }
}

.#{$prefix}__button-like {
    top: 0;
    right: 0;
    background: transparent url('../assets/svg/heart.svg') no-repeat center;
    &--liked {
        background-image: url('../assets/svg/heart-fill-black.svg');
    }
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix}__button-no,
    .#{$prefix}__button-yes {
        background-position: center;
    }
    .#{$prefix}__button-reject {
        width: 100%;
        height: 40px;
    }
}
