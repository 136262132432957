@import './styles/variables.scss';

.#{$prefix}__line {
    display: none;
    width: $left-panel-width;
    margin-left: -$left-panel-left-gap;
    margin-bottom: 25px;
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix}__line {
        display: block;
    }
}
