@import '../../../styles/variables.scss';

.#{$prefix} {
    color: #fff;
    position: relative;
    min-height: calc(100vh - #{$mobile-header-height});
    background-color: #8177f3;
    // background: radial-gradient(77.73% 77.73% at 98.52% 96.25%, #f98bda 0%, rgba(128, 76, 239, 0.29) 50.52%, rgba(91, 216, 216, 0) 100%),
    //     radial-gradient(141.73% 105.23% at 50% -7.16%, rgba(225, 248, 255, 0.7) 0%, rgba(160, 198, 255, 0) 50.73%, rgba(162, 147, 255, 0) 100%),
    //     radial-gradient(112.27% 48.54% at 1.59% 50%, rgba(255, 130, 227, 0.5) 0%, rgba(255, 123, 234, 0.095) 53.91%, rgba(254, 216, 255, 0) 100%),
    //     linear-gradient(153.07deg, #8177f3 6.37%, #8fc8f1 83.82%);
}

.#{$prefix}__content {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.#{$prefix}__facade-image {
    margin-bottom: 125px;
}

.#{$prefix}__input-text-link {
    background: none;
    padding: 0;
    border-radius: 0;
    width: 325px;
    height: 65px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    font-size: 13px;
    color: #fff;
    padding: 25px;
    text-align: center;
    outline: none;
    &:focus {
        border-color: #fff;
    }
    &--valued {
        border-color: #fff;
        border-radius: 32px;
    }
    &-error {
        border-color: var(--red);
    }
}

.custom-file-input,
.#{$prefix}__file-value {
    width: 325px;
    height: 65px;
    background-color: #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: var(--text-black);
}

.custom-file-input {
}
.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}
.custom-file-input::before {
    content: 'Загрузить фото';
    display: inline-block;
    padding: 25px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    text-align: center;
}
.custom-file-input:hover::before {
    border-color: black;
}
.custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.#{$prefix}__file-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.#{$prefix}__file-value-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 325px;
    overflow: hidden;
    margin-right: 10px;
}

.#{$prefix}__file-image {
    width: 50px;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    flex-shrink: 0;
    margin-right: 10px;
}

.#{$prefix}__button-find {
    width: 325px;
    height: 65px;
    background-color: #fff;
    color: var(--text-black);
    border: none;
    margin-bottom: 25px;
    &:disabled {
        color: rgba(255, 255, 255, 0.5);
        background-color: rgba(255, 255, 255, 0.2);
    }
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix} {
        min-height: 100vh;
    }
}
