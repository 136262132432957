$prefix: 'garderobo';

$mobile-small: 375px;
$desktop-small: 1024px;
$desktop-medium: 1280px;
$desktop-large: 1440px;

$mobile-header-height: 40px;
$side-gap-mob: 25px;
$side-gap-desktop: 50px;
$left-panel-width: 280px;
$left-panel-left-gap: 60px;
$round-btn-size: 25px;

$option-btn-small-height: 50px;
$option-btn-big-height: 65px;
$option-btn-big-height-desktop: 70px;
