@import '../../../styles/variables.scss';

.#{$prefix} {
    display: inline-flex;
    position: relative;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    align-items: center;
}

.#{$prefix}__text {
    margin-right: 10px;
    color: var(--red);
}

.#{$prefix}__question {
    border-radius: 50%;
    background-color: var(--red);
    color: #fff;
    width: $round-btn-size;
    height: $round-btn-size;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 300;
}

.#{$prefix}__tooltip {
    display: none;
    background-color: #fff;
    color: var(--text-black);
    padding: 10px;
    border: 1px solid var(--red);
    position: absolute;
    right: 0;
    bottom: $round-btn-size + 10px;
    &--opened {
        width: 200px;
        display: block;
        z-index: 300;
    }
}

.#{$prefix}__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 300;
}
