@import '../../../../styles/variables.scss';

.#{$prefix}__products {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    margin-bottom: 20px;
}

.#{$prefix}__product-picture {
    width: 155px;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
}

.#{$prefix}__user-product {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    margin-bottom: 15px;
}

.#{$prefix}__user-product-text {
    margin: 30px 0 50px 0;
}

.#{$prefix}__sticky-controls {
    display: flex;
    width: 100%;
    position: sticky;
    bottom: 0;
}

.#{$prefix}__controls-btn {
    width: 50%;
    border: 1px solid var(--border-gray);
    &--prev {
        border-right: none;
        &::before {
            content: url('../../../../assets/svg/arrow-right-black.svg');
            margin-right: 20px;
            transform: rotate(180deg);
        }
    }
    &--next {
        &::after {
            content: url('../../../../assets/svg/arrow-right-black.svg');
            margin-left: 20px;
        }
    }
}

.#{$prefix}__btn-buy {
    width: 100%;
    margin-bottom: 5px;
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix} {
        &--cols-2 {
            .#{$prefix}__layout-center {
                width: 530px;
            }
            .#{$prefix}__layout-right {
                //width: calc(100% - 530px);
                width: calc(100% - 530px - #{$left-panel-width});
            }
            .#{$prefix}__products {
                grid-template-columns: 1fr 1fr;
                grid-gap: 20px;
            }
            .#{$prefix}__main-block {
                display: flex;
                grid-gap: 50px;
            }
            .#{$prefix}__main-block-card-wrapper {
                width: 250px;
                flex-shrink: 0;
                padding-top: 30px;
            }
            .#{$prefix}__main-block-card {
                width: 250px;
            }
            .#{$prefix}__main-block-card-pic {
                width: 250px;
                height: 320px;
            }
        }

        &--cols-3 {
            .#{$prefix}__layout-center {
                width: 760px;
            }
            .#{$prefix}__layout-right {
                width: calc(100% - 760px);
            }
            .#{$prefix}__products {
                grid-template-columns: 1fr 1fr 1fr;
                grid-gap: 20px;
            }
        }
    }
    .#{$prefix}__layout-center {
        order: 2;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 100px 50px 50px;
        width: 100%;
        background-color: var(--back-gray);
        position: absolute;
        right: 0;
    }
    .#{$prefix}__layout-right {
        order: 1;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        border-right: 1px solid var(--border-gray);
        padding: 45px 50px 50px;
        position: fixed;
        width: calc(100% - 530px - #{$left-panel-width});
        height: 100vh;
        overflow-y: scroll;
    }
    .#{$prefix}__product {
        width: 205px;
    }
    .#{$prefix}__product-picture {
        width: 205px;
        height: 260px;
    }
    .#{$prefix}__btn-buy {
        width: 100%;
    }
    .#{$prefix}__controls {
        display: flex;
        grid-gap: 20px;
        width: 100%;
    }
    .#{$prefix}__controls-btn {
        &--prev {
            width: 100%;
            border: 1px solid var(--border-gray);
        }
        &--next {
            width: 100%;
        }
    }
    .#{$prefix}__controls-next-prev {
        width: 100%;
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
    }
}

@media screen and (min-width: $desktop-large + 1px) {
    .#{$prefix} {
        &--cols-2 {
            .#{$prefix}__layout-right {
                position: static;
                width: calc(100% - 530px);
            }
        }
    }
    .#{$prefix}__layout-center {
        height: 100vh;
        overflow-y: scroll;
    }
}
