@import '../../../../styles/variables.scss';

.#{$prefix}__prices {
    width: 100%;
    display: flex;
    grid-gap: 5px;
    margin-bottom: 30px;
    overflow-x: scroll;
}

.#{$prefix}__price {
    padding: 18px 20px;
    border: 1px solid var(--border-gray);
    text-align: center;
    &--selected {
        border-color: var(--text-black);
        border-radius: 90px;
    }
}

.#{$prefix}__prices-dropdown {
    margin-bottom: 15px;
}

.#{$prefix}__looks {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    margin-bottom: 30px;
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix}__layout-center {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 50px;
        width: 100%;
    }
    .#{$prefix}__layout-right {
        display: none;
    }
    .#{$prefix}__btn-back {
        margin-bottom: 50px;
    }
    .#{$prefix}__options {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 35px;
    }
    .#{$prefix}__page-title-left {
        width: auto;
        padding: 0;
    }
    .#{$prefix}__prices {
        width: auto;
        margin: 0;
        overflow-x: unset;
    }
    .#{$prefix}__prices-dropdown {
        margin: 0;
    }
    .#{$prefix}__looks {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 20px;
    }
    .#{$prefix}__controls {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .#{$prefix}__btn {
        width: 520px;
    }
    .#{$prefix}__product-card {
        width: 250px;
    }
    .#{$prefix}__product-pic {
        width: 250px;
        height: 320px;
    }
}

@media screen and (min-width: $desktop-large) {
    .#{$prefix}__layout-center {
        width: 100%;
    }
}
