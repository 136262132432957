@import '../styles/variables.scss';

.#{$prefix}__gradients {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    overflow: hidden;
}

.#{$prefix}__gradients-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 430px;
    z-index: 0;
    background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0.991353) 6.67%,
        rgba(255, 255, 255, 0.96449) 13.33%,
        rgba(255, 255, 255, 0.91834) 20%,
        rgba(255, 255, 255, 0.852589) 26.67%,
        rgba(255, 255, 255, 0.768225) 33.33%,
        rgba(255, 255, 255, 0.668116) 40%,
        rgba(255, 255, 255, 0.557309) 46.67%,
        rgba(255, 255, 255, 0.442691) 53.33%,
        rgba(255, 255, 255, 0.331884) 60%,
        rgba(255, 255, 255, 0.231775) 66.67%,
        rgba(255, 255, 255, 0.147411) 73.33%,
        rgba(255, 255, 255, 0.0816599) 80%,
        rgba(255, 255, 255, 0.03551) 86.67%,
        rgba(255, 255, 255, 0.0086472) 93.33%,
        rgba(255, 255, 255, 0) 100%
    );
}

.#{$prefix}__red-wrapper {
    //border: 1px solid red;
    position: absolute;
    left: 0;
    top: 50%;
    /*transform: translate(10%, -50%);*/
}

.#{$prefix}__red2-wrapper {
    //border: 1px solid red;
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(50%, 10%);
    width: 400px;
    height: 400px;
}

.#{$prefix}__blue-wrapper {
    //border: 1px solid blue;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translate(-50%, 50%);
    width: 400px;
    height: 400px;
}

.#{$prefix}__red,
.#{$prefix}__red2,
.#{$prefix}__green,
.#{$prefix}__blue {
    position: absolute;
    // top: 50%;
    // left: 50%;
    width: 400px;
    height: 400px;
    //margin: -200px 0 0 -200px;
}
.#{$prefix}__red {
    left: 0;
    top: 50%;
    margin: -200px 0 0 -200px;
    //transform: translate(-50%, -50%);
}
.#{$prefix}__red2 {
    right: 0;
    top: 50%;
    margin: -200px 0 0 -200px;
    //transform: translate(-50%, -50%);
}
@keyframes red {
    0% {
        -webkit-transform: rotate(0deg) translate(-40px) rotate(0deg) scale(1, 1);
    }
    50% {
        -webkit-transform: rotate(-180deg) translate(-40px) rotate(-180deg) scale(1, 1.33);
    }
    100% {
        -webkit-transform: rotate(-360deg) translate(-40px) rotate(-360deg) scale(1, 1);
    }
}
.#{$prefix}__red,
.#{$prefix}__red2 {
    -webkit-animation: 7s red linear infinite;
    -moz-animation: 7s red linear infinite;
    background-image: -moz-radial-gradient(50% 50%, circle contain, rgba(255, 130, 227, 0.75), rgba(222, 75, 218, 0) 100%);
    background-image: -webkit-radial-gradient(50% 50%, circle contain, rgba(255, 130, 227, 0.75), rgba(222, 75, 218, 0) 100%);
}

.#{$prefix}__blue,
.#{$prefix}__blue2 {
    -webkit-animation: 7s red linear infinite;
    -moz-animation: 7s red linear infinite;
    background-image: -moz-radial-gradient(50% 50%, circle contain, rgba(143, 200, 241, 0.9), transparent 100%);
    background-image: -webkit-radial-gradient(50% 50%, circle contain, rgba(143, 200, 241, 0.9), transparent 100%);
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix}__gradients-overlay {
        display: none;
    }
}
