@import '../../../styles/variables.scss';

@media screen and (min-width: $desktop-small) {
    .#{$prefix}__layout-center {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 0 50px 50px;
        width: 100%;
    }
    .#{$prefix}__page-title-left {
        width: auto;
    }
}
