@import '../../../styles/variables.scss';

$height-mob: 35px;

.#{$prefix} {
    width: 150px;
    max-width: 100%;
    position: relative;
    padding: 0 10px;
    border: 1px solid transparent;
    height: $height-mob;
    &--opened {
        border: 1px solid var(--text-black);
        border-bottom: none;
        .#{$prefix}__value {
            border-bottom: 1px solid var(--text-black);
            &::after {
                transform: rotate(180deg);
                position: absolute;
                right: 0;
            }
        }
    }
}

.#{$prefix}__value {
    position: relative;
    cursor: pointer;
    height: $height-mob;
    display: flex;
    align-items: center;
    z-index: 5;
    &::after {
        content: '';
        //position: absolute;
        margin-left: 10px;
        right: 0;
        border-radius: 50%;
        border: 1px solid var(--border-gray);
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: url('../../../assets/svg/arrow-tick-down.svg') no-repeat center;
    }
}

.#{$prefix}__list {
    display: none;
    position: absolute;
    left: -1px;
    right: -1px;
    background-color: #fff;
    border: 1px solid var(--text-black);
    border-top: none;
    top: $height-mob - 1px;
    z-index: 1;
    &--opened {
        display: block;
    }
}

.#{$prefix}__list-item {
    border-bottom: 1px solid var(--border-gray);
    padding: 10px;
    cursor: pointer;
    &--selected:first-child {
        border-top: 1px solid var(--border-gray);
    }
    &:last-child {
        border-bottom: none;
    }
    &--selected {
        cursor: default;
        background-color: var(--back-gray);
        border-bottom: 1px solid var(--border-gray);
        border-right: none;
        border-left: none;
    }
}

@media screen and(min-width: $desktop-small) {
    .#{$prefix} {
        height: $option-btn-small-height;
        padding: 0 20px;
        width: 200px;
    }
    .#{$prefix}__value {
        height: $option-btn-small-height;
        border-bottom: 1px solid var(--text-black);
        &::after {
            position: absolute;
            right: 0;
            border: none;
        }
    }
    .#{$prefix}__list {
        top: $option-btn-small-height - 1px;
    }
    .#{$prefix}__list-item {
        padding: 15px 20px;
    }
}
