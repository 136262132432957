@import '../../../styles/variables.scss';

$width: 325px;
$pic-width-big: 390px;
$pic-height-big: 550px;
$robot-closed-height: 100px;

.#{$prefix}__layout {
    flex-direction: column;
}

.#{$prefix}__content {
    padding: 0 $side-gap-mob;
    padding-top: 25px;
}

.#{$prefix}__picture-container {
    position: relative;
    width: $width;
}

.#{$prefix}__picture {
    width: $width;
    height: 425px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 10px;
    cursor: pointer;
    border: none;
    padding: 0;
    margin: 0;
}

.#{$prefix}__details {
    width: $width;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.#{$prefix}__brand {
    color: var(--text-gray);
    width: 260px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.#{$prefix}__button-buy {
    margin-bottom: 15px;
}

.#{$prefix}__robot-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    left: 0;
    bottom: 0;
    width: 100vw;
    text-align: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 15px 25px 25px 25px;
    z-index: 300;
    background: radial-gradient(119.35% 65.46% at 127.68% 15.55%, #fff0ba 0%, rgba(250, 144, 78, 0) 100%),
        radial-gradient(81.09% 160.32% at 10.91% 7.43%, rgba(255, 132, 79, 0.7) 0%, rgba(253, 169, 107, 0) 100%),
        radial-gradient(38.62% 73.86% at 46.15% 108.51%, #fff8bc 2.1%, rgba(254, 180, 120, 0) 100%), linear-gradient(135.54deg, #f2681c 1.29%, #ffb36c 75.24%);
}

.#{$prefix}__robot-title {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: #fff;
    margin-bottom: 20px;
    width: 190px;
}

.#{$prefix}__thumbnails {
    display: flex;
    overflow-x: scroll;
    width: 100%;
}
.#{$prefix}__btn-thumbnail {
    background: none;
    border: none;
    padding: 0;
    flex-shrink: 0;
    cursor: pointer;
    width: 100vw;
    height: 600px;
}

.#{$prefix}__btn-thumbnail-pic {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.#{$prefix}__overlay {
    position: fixed;
    padding-top: 20px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 350;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.#{$prefix}__overlay-pic {
    display: none;
    width: 100vw;
    height: fit-content;
}

.#{$prefix}__btn-close {
    margin: 0 25px 20px 0;
}

@media screen and (min-height: 780px) {
    .#{$prefix}__robot-block {
        position: fixed;
        left: 0;
    }
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix}__layout {
        flex-direction: row;
        overflow: hidden;
        padding-bottom: $robot-closed-height;
        &--overlayed {
            padding-bottom: 0;
        }
    }
    .#{$prefix}__layout-center {
        padding: 45px $side-gap-desktop $side-gap-desktop;
        width: auto;
    }
    .#{$prefix}__layout-right {
        padding: 102px $side-gap-desktop $side-gap-desktop;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
    }
    .#{$prefix}__layout-right-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: $pic-height-big;
        width: 100%;
    }

    .#{$prefix}__btn-back {
        margin-bottom: 30px;
    }
    .#{$prefix}__pictures {
        display: flex;
        grid-gap: 20px;
    }
    .#{$prefix}__thumbnails {
        max-height: 95vh;
        overflow: auto;
        overflow-x: hidden;
        flex-direction: column;
        flex-shrink: 0;
        grid-gap: 20px;
        width: auto;
        height: $pic-height-big;
    }
    .#{$prefix}__btn-thumbnail {
        width: 130px;
        height: 170px;
        flex-shrink: 0;
        cursor: pointer;
    }

    .#{$prefix}__picture-container {
        width: $pic-width-big;
        height: $pic-height-big;
    }
    .#{$prefix}__picture {
        width: $pic-width-big;
        height: $pic-height-big;
    }

    .#{$prefix}__brand {
        color: var(--text-black);
        margin-bottom: 5px;
    }
    .#{$prefix}__name {
        color: var(--text-gray);
    }
    .#{$prefix}__price {
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 20px;
    }
    .#{$prefix}__button-buy {
        margin-bottom: 20px;
        width: 100%;
    }
    .#{$prefix}__sizes-title {
        color: var(--text-gray);
        margin-bottom: 10px;
    }
    .#{$prefix}__sizes-list {
        display: flex;
        grid-gap: 10px;
        margin-bottom: 40px;
    }

    .#{$prefix}__robot-block {
        position: absolute;
        left: $side-gap-desktop;
        right: $side-gap-desktop;
        width: auto;
        padding: 42px 50px 50px 50px;
        bottom: -115px;
        transition: bottom ease-in-out 0.1s;
        &--opened {
            bottom: 0;
            &::before {
                transform: rotate(180deg);
            }
        }
        &::before {
            content: url('../../../assets/svg/arrow-double-down-white.svg');
            position: absolute;
            top: 42px;
            left: 50px;
            display: block;
            transform-origin: center 10px;
        }
    }
    .#{$prefix}__robot-title {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 35px;
        width: auto;
    }

    .#{$prefix}__overlay {
        position: absolute;
        padding: 0;
        flex-direction: row;
        align-items: flex-start;
        .#{$prefix}__thumbnails {
            padding: 0 90px 0 100px;
            height: 100%;
        }
        .#{$prefix}__picture {
            width: 520px;
            height: 100vh;
        }
    }

    .#{$prefix}__overlay-pic {
        display: block;
        height: 100vh;
        width: fit-content;
    }

    .#{$prefix}__btn-close {
        position: absolute;
        top: 40px;
        right: 50px;
        z-index: 360;
    }
}
