@import '../../../styles/variables.scss';

.#{$prefix}__header {
    margin-bottom: 20px;
}

.#{$prefix}__products {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    margin-bottom: 30px;
}

.#{$prefix}__product-card {
    width: 155px;
}
.#{$prefix}__product-card-pic {
    width: 155px;
    height: 220px;
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix}__layout-center {
        width: 100%;
    }
    .#{$prefix}__products {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 20px;
    }
    .#{$prefix}__product-card {
        width: 250px;
    }
    .#{$prefix}__product-card-pic {
        width: 250px;
        height: 350px;
    }
}
