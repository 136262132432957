@import '../../../styles/variables.scss';

.#{$prefix} {
    width: 155px;
    margin-bottom: 5px;
}

.#{$prefix}__picture-container {
    position: relative;
}

.#{$prefix}__picture {
    width: 100%;
    height: 200px;
    background-color: var(--border-gray);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 15px;
}

.#{$prefix}__details {
    display: flex;
    justify-content: space-between;
    grid-gap: 10px;
}

.#{$prefix}__details-info {
    width: calc(100% - 10px - 71px);
}

.#{$prefix}__brand {
    color: var(--text-gray);
    margin-bottom: 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
}

.#{$prefix}__overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow-y: scroll;
    padding: 20px 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    text-align: right;
    z-index: 200;
}

.#{$prefix}__overlay-pic {
    display: none;
    width: 100vw;
    height: fit-content;
}

.#{$prefix}__overlay-button-close {
    margin: 0 25px 20px 0;
}

.#{$prefix}__thumbnails {
    display: flex;
    overflow-x: scroll;
    width: 100%;
}
.#{$prefix}__btn-thumbnail {
    background: none;
    border: none;
    padding: 0;
    flex-shrink: 0;
    cursor: pointer;
    width: 100vw;
    height: 600px;
}

.#{$prefix}__btn-thumbnail-pic {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
