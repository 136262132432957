@import '../../../styles/variables.scss';

.#{$prefix} {
    position: relative;
    padding-left: $round-btn-size + 10px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    height: $round-btn-size;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        background: transparent url('../../../assets/svg/arrow-tick-left.svg') no-repeat center;
        width: $round-btn-size;
        height: $round-btn-size;
        border-radius: 50%;
        border: 1px solid var(--text-black);
        box-sizing: border-box;
    }
    &:hover {
        &:before {
            background: var(--text-black) url('../../../assets/svg/arrow-tick-right-white.svg') no-repeat center;
            transform: rotate(180deg);
        }
    }
}
